<template>
  <div>
    <v-container py-10 fluid>
      <p class="text-center">We've searched along the entire coast of Wales - in sea and under stone - but alas, we could not find that for which you are looking.</p>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "NotFound",
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>