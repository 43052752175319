<template>
  <v-card v-if="ready">
    <v-toolbar color="secondary" dark>
      <span style="text-decoration: none; margin-right: 5px">
        <a v-bind:href="listurl"
          ><span style="color: white">{{ fromlist }}:</span></a
        >
      </span>
      <span v-if="unid" style="text-decoration: none">
        Details for
        <a v-bind:href="selfurl"
          ><span style="color: white">{{ niceuind }}</span></a
        >
      </span>
      <span v-if="!unid" style="text-decoration: none"> {{ niceuind }} </span>
      <v-spacer></v-spacer>
      {{ savemsg }}
      <v-spacer></v-spacer>
      <span class="text-right">
        <v-card-actions>
          <v-btn color="white darken-3" text @click="safeClose">Close</v-btn>
          <v-btn color="white darken-1" text @click="saveChanges">Save</v-btn>
        </v-card-actions>
      </span>
    </v-toolbar>
    <v-alert
      :value="alert"
      color="tertiary darken-1"
      dark
      border="top"
      icon="mdi-alert-circle-outline"
      transition="scale-transition"
    >
      {{ alertmsg }}
    </v-alert>
    <v-card-text>
      <v-container>
        <v-row align="center">
          <v-col cols="2">
            <v-select
              v-model="currassign"
              :items="allmembers"
              label="Assigned"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-btn icon color="grey" x-small v-on:click="currassign = ''">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1"
            ><v-select
              v-model="currstatus"
              :items="allstatus"
              label="Status"
            ></v-select
          ></v-col>
          <v-col cols="1"
            ><v-select
              v-model="currhealth"
              :items="allhealth"
              label="Health"
            ></v-select
          ></v-col>
          <v-col cols="1">
            <v-btn
              rounded
              color="secondary"
              dark
              x-small="true"
              v-on:click="transfer()"
              >Transfer</v-btn
            >
          </v-col>
          <v-col cols="3"
            ><v-select
              v-model="currteams"
              :items="allteams"
              multiple
              chips
              label="Teams"
            ></v-select
          ></v-col>
          <v-col cols="3"
            ><v-select
              v-model="currguests"
              :items="allteams"
              multiple
              chips
              label="Guests"
            ></v-select
          ></v-col>
        </v-row>
        <div>
          <v-row v-bind:key="index" v-for="(oneitem, index) in itemdata">
            <v-col v-bind:key="val.unid" v-for="(val, key) in oneitem">
              <v-textarea
                v-model="oneitem[key]"
                :label="nicekey(key)"
                auto-grow
                rows="1"
                v-on:blur="checkSave"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-card-text>
    <v-alert
      :value="alert"
      color="tertiary darken-1"
      dark
      border="top"
      icon="mdi-alert-circle-outline"
      transition="scale-transition"
    >
      {{ alertmsg }}
    </v-alert>
    <v-card-actions>
      <v-btn v-if="unid" color="accent darken-3" text @click="delItem"
        >Delete</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="grey darken-3" text @click="safeClose">Close</v-btn>
      <v-btn color="accent darken-1" text @click="saveChanges">Save</v-btn>
    </v-card-actions>
    <v-card-text class="logtext">
      <v-container>
        <v-row dense v-bind:key="index" v-for="(log, index) in logentries">
          <v-col style="">{{ log }}</v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style>
.logtext {
  color: "black lighten-1";
  font-family: "Courier New", Courier, monospace;
}
</style>

<script>
import axios from "axios";

export default {
  name: "Form",
  props: {
    unid: String,
    ftype: String,
    baseurl: String,
  },
  beforeRouteEnter(to, from, next) {
    //Check if there is a params
    //console.log("Form: Checking route enter params")
    if (Object.keys(to.params).length !== 0) {
      next((vm) => {
        vm.ftype = to.params.ftype;
        //console.log("Set ftype: " + to.params.ftype)
      });
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    //Check if there is a params
    //console.log("Form: Checking route update params")
    if (Object.keys(to.params).length !== 0) {
      next((vm) => {
        vm.ftype = to.params.ftype;
        //console.log("Set ftype: " + to.params.ftype)
      });
    }
    next();
  },
  data: () => ({
    ready: false,
    itemdata: [],
    origitemdata: [],
    needsave: false,
    dorefresh: false,
    alert: false,
    alertmsg: "",
    savemsg: "",
    allteams: [],
    currteams: [],
    origcurrteams: [],
    currguests: [],
    origcurrguests: [],
    allstatus: [],
    currstatus: "",
    origcurrstatus: "",
    allmembers: [],
    currassign: "",
    origcurrassign: "",
    allhealth: [],
    currhealth: "",
    origcurrhealth: "",
    logentries: [],
  }),
  computed: {
    selfurl: function () {
      let hname = window.location.hostname;
      if (hname.endsWith("/")) {
        hname = "https://" + hname + "item/" + this.ftype;
      } else {
        hname = "https://" + hname + "/item/" + this.ftype;
      }
      if (this.unid == "") {
        return hname;
      }
      return hname + "?unid=" + this.unid;
    },
    niceuind: function () {
      if (this.unid == "") {
        if (this.ftype == "cm") {
          return "New Change";
        } else {
          return "New Server";
        }
      }
      if (this.ftype == "cm") {
        return "CM-" + this.unid;
      }
      return this.unid;
    },
    listurl: function () {
      let hname = window.location.hostname;
      if (hname.endsWith("/")) {
        hname = "https://" + hname + "inv/" + this.ftype;
      } else {
        hname = "https://" + hname + "/inv/" + this.ftype;
      }
      return hname;
    },
    fromlist: function () {
      if (this.ftype == "srv") {
        return "Servers";
      }
      if (this.ftype == "cm") {
        return "Changes";
      }
      return "List";
    },
  },
  watch: {
    currteams: function () {
      this.getStatusOpts();
      this.getHealthOpts();
      this.checkSave();
    },
    currguests: function () {
      this.checkSave();
    },
    currstatus: function () {
      this.checkSave();
    },
    currassign: function () {
      this.checkSave();
    },
    currhealth: function () {
      this.checkSave();
    },
  },
  methods: {
    safeClose() {
      if (this.alert) {
        this.unid = "";
        this.$emit("childToParent", true);
      }
      this.checkSave();
      if (this.needsave) {
        this.alertmsg =
          "You have unsaved changes. Click Close again to discard changes or else click Save.";
        this.alert = true;
      } else {
        this.unid = "";
        this.$emit("childToParent", true);
      }
    },
    nicekey: function (val) {
      const rawkey = String(val);
      if (rawkey.includes("_")) {
        return rawkey.replace("_", " ");
      }
      return rawkey;
    },
    getStatusOpts() {
      let path = "";
      if (this.ftype == "srv") {
        path =
          "https://cisa.mail.ibm.com/api/status_list?teams=" +
          this.currteams.join();
      }
      if (this.ftype == "cm") {
        path =
          "https://cisa.mail.ibm.com/api/status_list?teams=" +
          this.currteams.join();
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.allstatus = res.data.allstatus;
            } else {
              this.savemsg = res.data.errmsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    getHealthOpts() {
      let path = "";
      if (this.ftype == "srv") {
        path =
          "https://cisa.mail.ibm.com/api/health_list?teams=" +
          this.currteams.join();
      }
      if (this.ftype == "cm") {
        path =
          "https://cisa.mail.ibm.com/api/health_list?teams=" +
          this.currteams.join();
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.allhealth = res.data.allhealth;
            } else {
              this.savemsg = res.data.errmsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    transfer() {
      let path = "";
      if (this.ftype == "srv") {
        path = "https://cisa.mail.ibm.com/api/transfer?unid=" + this.unid;
      }
      if (this.ftype == "cm") {
        return;
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.savemsg = res.data.savemsg;
              this.alert = false;
              this.dorefresh = true;
              this.getItem(true);
            } else {
              this.savemsg = res.data.errmsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    delItem() {
      if (!this.alert) {
        this.alertmsg = "Click Delete again to confirm deletion.";
        this.alert = true;
        return;
      }
      let path = "";
      if (this.ftype == "srv") {
        path = "https://cisa.mail.ibm.com/api/del_inv?unid=" + this.unid;
      }
      if (this.ftype == "cm") {
        path = "https://cisa.mail.ibm.com/api/del_cm?unid=" + this.unid;
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.alert = false;
              this.needsave = false;
              this.dorefresh = true;
              this.safeClose();
            } else {
              this.savemsg = res.data.errmsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    saveChanges() {
      if (!this.needsave) {
        this.checkSave();
        if (!this.needsave) {
          return;
        }
      }
      const path = "https://cisa.mail.ibm.com/api/save_inv";

      this.itemdata.push({ unid: this.unid });
      this.itemdata.push({ ftype: this.ftype });
      this.itemdata.push({ Teams: this.currteams.join() });
      this.itemdata.push({ Guests: this.currguests.join() });
      this.itemdata.push({ Status: this.currstatus });
      this.itemdata.push({ Assigned: this.currassign });
      this.itemdata.push({ Health: this.currhealth });

      axios
        .post(path, this.itemdata)
        .then((res) => {
          const status = res.data.status;
          this.savemsg = res.data.savemsg;
          if (status == "success") {
            this.alert = false;
            this.needsave = false;
            this.dorefresh = true;
            this.unid = res.data.unid;
            this.getItem(true);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.savemsg = error.response.data.savemsg;
        });
    },
    checkSave() {
      if (this.itemdata.length < 1) {
        return;
      }
      if (
        JSON.stringify(this.itemdata) === JSON.stringify(this.origitemdata) &&
        JSON.stringify(this.currteams) === JSON.stringify(this.origcurrteams) &&
        JSON.stringify(this.currguests) ===
          JSON.stringify(this.origcurrguests) &&
        JSON.stringify(this.currstatus) ===
          JSON.stringify(this.origcurrstatus) &&
        JSON.stringify(this.currassign) === JSON.stringify(this.origcurrassign) &&
        JSON.stringify(this.currhealth) === JSON.stringify(this.origcurrhealth)
      ) {
        this.needsave = false;
      } else {
        this.needsave = true;
        this.savemsg = "";
      }
    },
    getItem(doready) {
      this.ready = doready;

      if (typeof this.unid == "undefined") {
        this.unid = "";
      }

      if (this.ftype == "") {
        this.ftype = "srv";
      }
      let path = "";
      if (this.ftype == "srv") {
        path =
          "https://cisa.mail.ibm.com/api/single_inv?format=yes&unid=" +
          this.unid;
      }
      if (this.ftype == "cm") {
        path =
          "https://cisa.mail.ibm.com/api/single_cm?format=yes&unid=" +
          this.unid;
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.itemdata = res.data.item;
              this.origitemdata = JSON.parse(JSON.stringify(this.itemdata));
              this.allteams = res.data.allteams;
              this.currteams = res.data.currteams;
              this.origcurrteams = JSON.parse(JSON.stringify(this.currteams));
              this.currguests = res.data.currguests;
              this.origcurrguests = JSON.parse(JSON.stringify(this.currguests));
              this.allstatus = res.data.allstatus;
              this.currstatus = res.data.currstatus;
              this.origcurrstatus = JSON.parse(JSON.stringify(this.currstatus));
              this.allmembers = res.data.allmembers;
              this.currassign = res.data.currassign;
              this.origcurrassign = JSON.parse(JSON.stringify(this.currassign));
              this.allhealth = res.data.allhealth;
              this.currhealth = res.data.currhealth;
              this.origcurrhealth = JSON.parse(JSON.stringify(this.currhealth));
              this.ready = true;
            } else {
              this.savemsg = res.data.errmsg;
              this.ready = true;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
        if (this.unid != "") {
          this.getLog();
        }
      }
    },
    getLog() {
      let path = "";
      if (this.ftype == "srv") {
        path =
          "https://cisa.mail.ibm.com/api/get_log?type=inv&unid=" +
          this.unid;
      }
      if (this.ftype == "cm") {
        path =
          "https://cisa.mail.ibm.com/api/get_log?type=cm&unid=" + this.unid;
      }

      if (path != "") {
        axios
          .get(path)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.logentries = res.data.logentries;
            } else {
              this.savemsg = res.data.errmsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
    },
    authAPI() {
      const path = "https://cisa.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://cisa.mail.ibm.com/api/login";
          } else {
            this.getItem(false);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>