<template>
  <v-card class="mx-10 my-5">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>{{ helptxt }}</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <p>Ceridwen (<a href="https://upload.wikimedia.org/wikipedia/commons/7/76/Ceridwen.wav">pronunciation</a>) is a mythological figure from Welsh medieval legened who symbolizes change and transformation.</p>
          <p>This tool is intended to track bringing servers online then manage them through Change Requests. 
          Detailed documentation and useful scripts can be found in the <a href="https://ibm.box.com/s/s7diiz9t1lghti7pmvseq4jtpye3t2su" target="blank">Web Inventory Tool</a> Box folder.</p>
        </v-row>
      </v-container>
  </v-card>
</template>

<script>
  export default {
    name: 'Help',
    data: () => ({
      helptxt: "Help Page"
    }),
  }
</script>
