import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Item from '../views/Item.vue'
import Inventory from '../components/Inventory.vue'
import Teams from '../components/Teams.vue'
import Token from '../components/Token.vue'
import Bulk from '../components/Bulk.vue'
import User from '../components/User.vue'
import Help from '../components/Help.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/inv/:ftype',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/item/:ftype',
    name: 'Item',
    component: Item
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/token',
    name: 'Token',
    component: Token
  },
  {
    path: '/bulk/:ftype',
    name: 'Bulk',
    component: Bulk
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
