<template>
  <v-card class="mx-10 my-5">
    <Form
      :key="componentKey"
      :unid="unid"
      :ftype="ftype"
      v-on:childToParent="onChildClick"
    />
  </v-card>
</template>

<script>
// @ is an alias to /src
import Form from "@/components/Form.vue";

export default {
  name: "Item",
  components: {
    Form,
  },
  beforeRouteEnter(to, from, next) {
    //Check if there is a query
    if (Object.keys(to.query).length !== 0) {
      next((vm) => {
        vm.unid = to.query.unid;
      });
    }
    //Check if there is a params
    //console.log("Item: Checking route enter params")
    if (Object.keys(to.params).length !== 0) {
      next((vm) => {
        vm.ftype = to.params.ftype;
      });
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    //Check if there is a params
    //console.log("Item: Checking route update params. ")
    if (Object.keys(to.params).length !== 0) {
        this.ftype = to.params.ftype;
        //console.log("Set ftype: " + to.params.ftype);
        this.forceRender();
    }
    next();
  },
  data: () => ({
    unid: "",
    ftype: "",
    componentKey: 0,
  }),
  methods: {
    onChildClick(value) {
      if (value) {
        // Save handled by component
        this.unid = ""
      }
      this.forceRender()
    },
    forceRender() {
      this.componentKey += 1;
    },
  },
};
</script>
