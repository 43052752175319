<template>
  <div>
    <v-card class="mx-10 my-5">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Teams</v-toolbar-title>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-text-field v-model="teamName" label="Team Name"></v-text-field>
          </v-col>
          <v-col>
            <v-textarea
              v-model="teamOwners"
              label="Team Owners"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="statusopts"
              label="Status Options"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="healthopts"
              label="Health Options #-text (1=green,2=red,3=yellow,4=blue,5=orange,6=purple,7=white,default=grey)"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-textarea
              v-model="teamMembers"
              label="Team Members"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense v-if="savemsg != ''">
          <v-col>
            <v-card-text color="tertiary darken-1">{{ savemsg }}</v-card-text>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn v-if="isNew" color="primary lighten-1" text @click="createTeam()"
          >Create</v-btn
        >
        <v-btn
          v-if="!isNew"
          color="primary lighten-1"
          text
          @click="updateTeam()"
          >Update</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn v-if="!isNew" color="primary lighten-1" text @click="clearTeam()"
          >Clear</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isNew"
          text
          color="primary lighten-1"
          @click="deleteTeam()"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-container mt-5>
      <v-data-table
        :headers="headers"
        :items="allteams"
        :items-per-page="15"
        class="elevation-1"
        @click:row="loadTeam"
      >
        <template v-slot:item.members="{ item }">{{
          chopText(item.members)
        }}</template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
const maxtextlen = 300;

export default {
  name: "Teams",
  data: () => ({
    isNew: true,
    savemsg: "",
    dodelete: false,
    teamName: "",
    teamOwners: "",
    teamMembers: "",
    statusopts: "New",
    healthopts: "Good",
    allteams: [],
    headers: [
      {
        text: "Team Name",
        align: "start",
        value: "name",
      },
      { text: "Team Owners", value: "owners" },
      { text: "Team Members", value: "members" },
    ],
  }),
  methods: {
    loadTeam(value) {
      this.savemsg = "";
      this.teamName = value.name;
      this.teamOwners = value.owners;
      this.teamMembers = value.members;
      this.statusopts = value.statusopts;
      this.healthopts = value.healthopts;
      this.isNew = false;
      this.dodelete = false;
    },
    clearTeam() {
      this.savemsg = "";
      this.teamName = "";
      this.teamOwners = "";
      this.teamMembers = "";
      this.statusopts = "New";
      this.healthopts = "Good";
      this.isNew = true;
      this.dodelete = false;
    },
    chopText(textval) {
      let trunctext = textval.replace(/,/g, ", ").substring(0, maxtextlen);
      const lastspace = trunctext.lastIndexOf(" ");
      if (lastspace < 5 || textval.length < maxtextlen) {
        return trunctext;
      }
      return trunctext.substring(0, lastspace).trim() + "...";
    },
    getTeams() {
      const path = "https://cisa.mail.ibm.com/api/teams";

      axios
        .get(path)
        .then((res) => {
          this.allteams = res.data.allteams;
          this.email = res.data.email;
          if (this.teamOwners == "") {
            this.teamOwners = this.email;
          }
          this.dodelete = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getTeamInfo() {
      if (this.teamName == "") {
        return;
      }
      const path =
        "https://cisa.mail.ibm.com/api/singleteam?tname=" + this.teamName;

      axios
        .get(path)
        .then((res) => {
          this.teaminfo = res.data.teaminfo;
          this.teamName = this.teaminfo.name;
          this.teamOwners = this.teaminfo.owners;
          this.teamMembers = this.teaminfo.members;
          this.statusopts = this.teaminfo.statusopts;
          this.healthopts = this.teaminfo.healthopts;
          this.dodelete = false;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    createTeam() {
      const path = "https://cisa.mail.ibm.com/api/createteam";

      this.savemsg = "";
      this.dodelete = false;

      const tmparr_O = this.teamOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.teamMembers
        .trim()
        .split(/[ ,;\t\n]+/)
        .sort();
      const tmparr_S = this.statusopts.trim().split(/[,;\t\n]+/);
      const tmparr_H = this.healthopts.trim().split(/[,;\t\n]+/);
      let upentry = {
        name: this.teamName,
        owners: tmparr_O,
        members: tmparr_M,
        statusopts: tmparr_S,
        healthopts: tmparr_H,
      };

      axios
        .post(path, upentry)
        .then((res) => {
          const holdmsg = res.data.savemsg;
          if (holdmsg.startsWith("Created ")) {
            this.show_err = false;
            this.teaminfo = res.data.teaminfo;
            this.loadTeam(this.teaminfo);
            this.getTeams();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    updateTeam() {
      const path = "https://cisa.mail.ibm.com/api/updateteam";

      this.savemsg = "";
      this.dodelete = false;

      const tmparr_O = this.teamOwners.trim().split(/[ ,;\t\n]+/);
      const tmparr_M = this.teamMembers
        .trim()
        .split(/[ ,;\t\n]+/)
        .sort();
      const tmparr_S = this.statusopts.trim().split(/[,;\t\n]+/);
      const tmparr_H = this.healthopts.trim().split(/[,;\t\n]+/);
      let upentry = {
        name: this.teamName,
        owners: tmparr_O,
        members: tmparr_M,
        statusopts: tmparr_S,
        healthopts: tmparr_H,
      };

      axios
        .post(path, upentry)
        .then((res) => {
          const upstatus = res.data.status;
          const holdmsg = res.data.savemsg;
          if (upstatus == "success") {
            this.show_err = false;
            this.teaminfo = res.data.teaminfo;
            this.loadTeam(this.teaminfo);
            this.getTeams();
            this.savemsg = holdmsg;
          } else {
            this.show_err = true;
            this.savemsg = holdmsg;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    deleteTeam() {
      if (this.teamName == "") {
        return;
      }

      if (!this.dodelete) {
        this.dodelete = true;
        this.savemsg = "Click Delete again to confirm deletion."
        return
      }

      const path =
        "https://cisa.mail.ibm.com/api/delteam?tname=" + this.teamName;

      axios
        .get(path)
        .then((res) => {
          const status = res.data.status;
          if (status == "success") {
            this.clearTeam();
            this.getTeams();
          }
          this.dodelete = false;
          this.savemsg = res.data.savemsg;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://cisa.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://cisa.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
            this.getTeams();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>
