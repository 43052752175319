<template>
  <div>
    <v-container fluid>
      <v-card class="mx-10 my-5">
        <v-card-title class="justify-center">
          <span style="font-size: x-small;line-height: 80%;">Download<br>
          <v-btn icon color="grey" x-small v-on:click="downloadCSV">
            <v-icon>mdi-download</v-icon>
          </v-btn></span>
          <v-spacer></v-spacer>
          <v-select
            v-model="disptype"
            :items="disptypevals"
            label="Display Setting"
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            v-model="assignfilter"
            :items="uniqueassigned"
            label="Assigned Filter"
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            v-model="healthfilter"
            :items="uniquehealth"
            label="Health Filter"
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            v-model="statusfilter"
            :items="uniquestatuses"
            label="Status Filter"
          ></v-select>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filter"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-card-title>
        <v-data-table
          dense
          :headers="headers"
          :items="displaydataitems"
          item-key="unid"
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :items-per-page="perPageChoice"
          :search="search"
          class="elevation-1"
          @click:row="showDiag"
        >
          <template v-slot:item.Health="{ item }">
            <v-chip
              small
              :color="healthColor(item.Health, false)"
              :text-color="healthColor(item.Health, true)"
              >{{ healthText(item.Health) }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="2000px"
      @keydown.esc="safeClose"
    >
      <Form
        :key="componentKey"
        :unid="unid"
        :ftype="ftype"
        v-on:childToParent="onChildClick"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Form from "@/components/Form.vue";

export default {
  name: "Inventory",
  components: {
    Form,
  },
  beforeRouteEnter(to, from, next) {
    //Check if there is a params
    //console.log("Inv: Checking route enter params")
    if (Object.keys(to.params).length !== 0) {
      next((vm) => {
        vm.ftype = to.params.ftype;
      });
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    //Check if there is a params
    //console.log("Inv: Checking route update params")
    if (Object.keys(to.params).length !== 0) {
      this.ftype = to.params.ftype;
      //console.log("Set ftype: " + to.params.ftype)
      if (this.dialog) {
        this.forceRender();
      } else {
        this.getInventory();
      }
    }
    next();
  },
  data: () => ({
    email: "",
    userinfo: "",
    componentKey: 0,
    headers: [],
    dataitems: [],
    disptype: "Normal",
    disptypevals: ["Custom", "Minimal", "Normal", "All"],
    uniquestatuses: [],
    statusfilter: "[All]",
    uniqueassigned: [],
    assignfilter: "[All]",
    uniquehealth: [],
    healthfilter: "[All]",
    search: "",
    dialog: false,
    unid: "",
    ftype: "",
    perPageChoice: 20
  }),
  computed: {
    useremail: function () {
      if (typeof this.email != "undefined" && this.email != "") {
        return this.email;
      }
      return "";
    },
    displaydataitems() {
      if (
        this.statusfilter == "[All]" &&
        this.assignfilter == "[All]" &&
        this.healthfilter == "[All]"
      ) {
        return this.dataitems;
      }
      if (this.assignfilter == "[All]" && this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Status === this.statusfilter;
        });
      }
      if (this.statusfilter == "[All]" && this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Assigned === this.assignfilter;
        });
      }
      if (this.statusfilter == "[All]" && this.assignfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Health === this.healthfilter;
        });
      }
      if (this.statusfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Assigned === this.assignfilter && i.Health === this.healthfilter
          );
        });
      }
      if (this.assignfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Status === this.statusfilter && i.Health === this.healthfilter
          );
        });
      }
      if (this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Assigned === this.assignfilter && i.Status === this.statusfilter
          );
        });
      }
      return this.dataitems.filter((i) => {
        return (
          i.Status === this.statusfilter &&
          i.Assigned === this.assignfilter &&
          i.Health === this.healthfilter
        );
      });
    },
  },
  watch: {
    disptype: function () {
      this.forceRender();
      this.getInventory();
    },
  },
  methods: {
    onChildClick(value) {
      if (value) {
        this.forceRender();
        this.getInventory();
      }
      this.dialog = false;
    },
    forceRender() {
      this.componentKey += 1;
    },
    showDiag(value) {
      this.unid = value["unid"];
      this.forceRender();
      this.dialog = true;
    },
    healthText(healthvalue) {
      const textstart = healthvalue.indexOf("-") + 1;
      if (textstart > 0) {
        return healthvalue.slice(textstart);
      }
      return healthvalue;
    },
    healthColor(healthvalue, doTextColor) {
      const sevnum = healthvalue.charAt(0);
      // (1=green,2=red,3=yellow,4=blue,5=orange,6=purple,7=white,default=grey)
      if (sevnum == "1") {
        if (doTextColor) {
          return "white";
        }
        return "green";
      }
      if (sevnum == "2") {
        if (doTextColor) {
          return "white";
        }
        return "red";
      }
      if (sevnum == "3") {
        if (doTextColor) {
          return "black";
        }
        return "yellow";
      }
      if (sevnum == "4") {
        if (doTextColor) {
          return "white";
        }
        return "blue";
      }
      if (sevnum == "5") {
        if (doTextColor) {
          return "black";
        }
        return "orange";
      }
      if (sevnum == "6") {
        if (doTextColor) {
          return "white";
        }
        return "purple";
      }
      if (sevnum == "7") {
        if (doTextColor) {
          return "black";
        }
        return "white";
      }
      if (doTextColor) {
        return "black";
      }
      return "lightgrey";
    },
    getInventory() {
      if (this.ftype == "") {
        this.ftype = "srv";
      }
      if (this.ftype == "srv") {
        const path =
          "https://cisa.mail.ibm.com/api/inventory?disptype=" +
          this.disptype;

        axios
          .get(path)
          .then((res) => {
            this.headers = res.data.headers;
            this.dataitems = res.data.dataitems;
            this.uniquestatuses = res.data.uniquestatuses;
            this.uniqueassigned = res.data.uniqueassigned;
            this.uniquehealth = res.data.uniquehealth;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
      if (this.ftype == "cm") {
        const path = "https://cisa.mail.ibm.com/api/changes";

        axios
          .get(path)
          .then((res) => {
            this.headers = res.data.headers;
            this.dataitems = res.data.dataitems;
            this.displaydataitems = res.data.displaydataitems;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.error(error);
          });
      }
    },
    downloadCSV() {
      let allcols = [];
      let currline = "";
      for (let i = 0; i < this.headers.length; i++) {
        const key = this.headers[i]["value"];
        allcols.push(key);
        let entry = key.toString().replace(/"/g, "'");
        currline = currline + '"' + entry + '"' + ",";
      }
      currline = currline.slice(0, -1) + "\n";
      let csvdata = [currline];
      for (let i = 0; i < this.displaydataitems.length; i++) {
        currline = "";
        for (let j = 0; j < allcols.length; j++) {
          if (allcols[j] in this.displaydataitems[i]) {
            let entry = this.displaydataitems[i][allcols[j]].toString();
            if (entry.length > 20000) {
              entry = entry.slice(0, 20000);
            }
            entry = entry.replace(/"/g, "'");
            currline = currline + '"' + entry + '"' + ",";
          } else {
            currline = currline + '"0"' + ",";
          }
        }
        currline = currline.slice(0, -1) + "\n";
        csvdata.push(currline);
      }
      var blob = new Blob(csvdata, { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "CISA_Inventory.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getUserInfo() {
      const path = "https://cisa.mail.ibm.com/api/userinfo";

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          if (this.userinfo.customview != "") {
            this.disptype = "Custom";
          }
          this.perPageChoice = parseInt(this.userinfo.perPage)
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://cisa.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://cisa.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
            this.getUserInfo();
            this.getInventory();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>
