<template>
  <div>
    <Inventory msg="Ceridwen: IBM Change Management"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Inventory from '@/components/Inventory.vue'

export default {
  name: 'Home',
  components: {
    Inventory
  }
}
</script>
