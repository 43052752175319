<template>
  <div id="app" style="padding: 15px">
    <v-card max-width="1000" class="mx-auto">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>{{ userinfo.email }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-avatar>
          <v-img :src="imgpic" :alt="userinfo.firstname" />
        </v-avatar>
      </v-toolbar>
      <v-container fluid class="grey lighten-5">
        <v-row dense>
          <v-col>
            <v-text-field v-model="firstName" label="First Name"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field v-model="lastName" label="Last Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-select
            v-model="customview"
            :items="viewitems"
            label="Custom View"
            multiple
            clearable
          ></v-select>
        </v-row>
        <v-row dense>
          <v-select
            v-model="perPage"
            :items="perPageOpts"
            label="Items per Page"
          ></v-select>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn color="primary lighten-1" text @click="updateUser()"
          >Update</v-btn
        >
        <v-spacer></v-spacer>
        <v-card-text color="primary lighten-1">{{ savemsg }}</v-card-text>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "User",
  data() {
    return {
      show_err: false,
      savemsg: "",
      userinfo: "",
      firstName: "",
      lastName: "",
      customview: [],
      imgpic: "",
      viewitems: [],
      perPage: '20',
      perPageOpts: ['10', '20', '50', '100', 'All']
    };
  },
  methods: {
    getUserInfo() {
      const path = "https://cisa.mail.ibm.com/api/userinfo";

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          this.firstName = this.userinfo.firstName;
          this.lastName = this.userinfo.lastName;
          this.perPage = this.userinfo.perPage;
          if (this.perPage == '-1') {
            this.perPage = "All"
          }
          this.imgpic = res.data.imgpic;
          this.viewitems = res.data.viewitems;
          const tmpcustomview = this.userinfo.customview;
          if (tmpcustomview.includes(',')) {
            this.customview = tmpcustomview.split(",");
          } else {
            this.customview = [tmpcustomview]
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    updateUser() {
      const path = "https://cisa.mail.ibm.com/api/updateuser";

      this.savemsg = "";
      // const tmparr_V = this.customview.trim().split(/[ ,;\t\n]+/);
      let tmpperPage = this.perPage
      if (tmpperPage == "All") {
        tmpperPage = '-1'
      }
      let upentry = {
        firstName: this.firstName,
        lastName: this.lastName,
        customview: this.customview,
        perPage: tmpperPage
      };

      axios
        .post(path, upentry)
        .then((res) => {
          this.savemsg = res.data.savemsg;
          if (this.savemsg.startsWith("Updated ")) {
            this.show_err = false;
            this.getUserInfo();
          } else {
            this.show_err = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>
