<template>
  <div>
    <v-container fluid>
      <v-card class="mx-10 my-5">
        <v-container>
          <v-row>
            <v-col>
              <v-select
                v-model="disptype"
                :items="disptypevals"
                label="Display Setting"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="newassign"
                :items="allmembers"
                label="New Assign"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="newhealth"
                :items="allhealth"
                label="New Health"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="newstatus"
                :items="allstatus"
                label="New Status"
              ></v-select>
            </v-col>
            <v-col>
              <v-btn icon color="grey" x-small v-on:click="bulkUpdate">
                <v-icon>mdi-update</v-icon> </v-btn
              ><span style="font-size: 0.5em">Update</span>
            </v-col>
            <v-col>
              <v-select
                v-model="assignfilter"
                :items="uniqueassigned"
                label="Assigned Filter"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="healthfilter"
                :items="uniquehealth"
                label="Health Filter"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="statusfilter"
                :items="uniquestatuses"
                label="Status Filter"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          dense
          v-model="selected"
          show-select
          :headers="headers"
          :items="displaydataitems"
          item-key="unid"
          :footer-props="{ itemsPerPageOptions: [10, 20, 50, 100, -1] }"
          :items-per-page="perPageChoice"
          class="elevation-1"
        >
          <template v-slot:item.Health="{ item }">
            <v-chip
              small
              :color="healthColor(item.Health, false)"
              :text-color="healthColor(item.Health, true)"
              >{{ healthText(item.Health) }}</v-chip
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Bulk",
  data: () => ({
    email: "",
    userinfo: "",
    componentKey: 0,
    headers: [],
    dataitems: [],
    disptype: "Normal",
    disptypevals: ["Custom", "Minimal", "Normal", "All"],
    uniquestatuses: [],
    allstatus: [],
    statusfilter: "[All]",
    newstatus: "",
    uniqueassigned: [],
    allmembers: [],
    assignfilter: "[All]",
    newassign: "",
    uniquehealth: [],
    allhealth: [],
    healthfilter: "[All]",
    newhealth: "",
    ftype: "",
    selected: [],
    perPageChoice: 20
  }),
  computed: {
    displaydataitems() {
      if (
        this.statusfilter == "[All]" &&
        this.assignfilter == "[All]" &&
        this.healthfilter == "[All]"
      ) {
        return this.dataitems;
      }
      if (this.assignfilter == "[All]" && this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Status === this.statusfilter;
        });
      }
      if (this.statusfilter == "[All]" && this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Assigned === this.assignfilter;
        });
      }
      if (this.statusfilter == "[All]" && this.assignfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return i.Health === this.healthfilter;
        });
      }
      if (this.statusfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Assigned === this.assignfilter && i.Health === this.healthfilter
          );
        });
      }
      if (this.assignfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Status === this.statusfilter && i.Health === this.healthfilter
          );
        });
      }
      if (this.healthfilter == "[All]") {
        return this.dataitems.filter((i) => {
          return (
            i.Assigned === this.assignfilter && i.Status === this.statusfilter
          );
        });
      }
      return this.dataitems.filter((i) => {
        return (
          i.Status === this.statusfilter &&
          i.Assigned === this.assignfilter &&
          i.Health === this.healthfilter
        );
      });
    },
  },
  watch: {
    disptype: function () {
      this.forceRender();
      this.getInventory();
    },
  },
  methods: {
    forceRender() {
      this.componentKey += 1;
    },
    healthText(healthvalue) {
      const textstart = healthvalue.indexOf("-") + 1;
      if (textstart > 0) {
        return healthvalue.slice(textstart);
      }
      return healthvalue;
    },
    healthColor(healthvalue, doTextColor) {
      const sevnum = healthvalue.charAt(0);
      // (1=green,2=red,3=yellow,4=blue,5=orange,6=purple,7=white,default=grey)
      if (sevnum == "1") {
        if (doTextColor) {
          return "white";
        }
        return "green";
      }
      if (sevnum == "2") {
        if (doTextColor) {
          return "white";
        }
        return "red";
      }
      if (sevnum == "3") {
        if (doTextColor) {
          return "black";
        }
        return "yellow";
      }
      if (sevnum == "4") {
        if (doTextColor) {
          return "white";
        }
        return "blue";
      }
      if (sevnum == "5") {
        if (doTextColor) {
          return "black";
        }
        return "orange";
      }
      if (sevnum == "6") {
        if (doTextColor) {
          return "white";
        }
        return "purple";
      }
      if (sevnum == "7") {
        if (doTextColor) {
          return "black";
        }
        return "white";
      }
      if (doTextColor) {
        return "black";
      }
      return "lightgrey";
    },
    bulkUpdate() {
      if (this.ftype == "srv") {
        const path = "https://cisa.mail.ibm.com/api/bulk";

        let bulkdata = {
          newstatus: this.newstatus,
          newassign: this.newassign,
          newhealth: this.newhealth,
          srvslist: this.selected,
        };

        axios
          .post(path, bulkdata)
          .then((res) => {
            const status = res.data.status;
            if (status == "success") {
              this.savemsg = res.data.savemsg;
              this.getInventory();
            } else {
              this.savemsg = res.data.savemsg;
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    getInventory() {
      if (this.ftype == "") {
        this.ftype = "srv";
      }
      if (this.ftype == "srv") {
        const path =
          "https://cisa.mail.ibm.com/api/inventory?disptype=" +
          this.disptype;

        axios
          .get(path)
          .then((res) => {
            this.headers = res.data.headers;
            this.dataitems = res.data.dataitems;
            this.allstatus = res.data.allstatus;
            this.allmembers = res.data.allmembers;
            this.allhealth = res.data.allhealth;
            this.uniquestatuses = res.data.uniquestatuses;
            this.uniqueassigned = res.data.uniqueassigned;
            this.uniquehealth = res.data.uniquehealth;
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
      if (this.ftype == "cm") {
        const path = "https://cisa.mail.ibm.com/api/changes";

        axios
          .get(path)
          .then((res) => {
            this.headers = res.data.headers;
            this.dataitems = res.data.dataitems;
          })
          .catch((error) => {
            // eslint-disable-next-line
            this.savemsg = error.response.data.savemsg;
          });
      }
    },
    getUserInfo() {
      const path = "https://cisa.mail.ibm.com/api/userinfo";

      axios
        .get(path)
        .then((res) => {
          this.userinfo = res.data.userinfo;
          if (this.userinfo.customview != "") {
            this.disptype = "Custom";
          }
          this.perPageChoice = parseInt(this.userinfo.perPage)
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.savemsg = error.response.data.savemsg;
        });
    },
    authAPI() {
      const path = "https://cisa.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://cisa.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
            this.getUserInfo();
            this.getInventory();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.savemsg = error.response.data.savemsg;
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>