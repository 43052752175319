<template>
  <v-container>
    <v-row>
      <v-col> Email: {{ email }} </v-col>
      <v-col> Token: {{ token }} </v-col>
    </v-row>
    <v-row>
      <v-btn text color="secondary" @click="createToken">Create Token</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Token",
  data: () => ({
    email: "",
    token: "",
  }),
  methods: {
    createToken() {
      const path = "https://cisa.mail.ibm.com/api/dotoken";

      axios
        .get(path)
        .then((res) => {
          this.token = res.data.token;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    authAPI() {
      const path = "https://cisa.mail.ibm.com/api/gotcreds";

      axios
        .get(path)
        .then((res) => {
          let needauth = res.data.needauth;
          if (needauth == "yes") {
            window.location.href = "https://cisa.mail.ibm.com/api/login";
          } else {
            this.email = res.data.email;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.authAPI();
  },
};
</script>
